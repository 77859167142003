var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"timeline flex-column-center"},[_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center first"},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center dos"},[_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center tres"},[_vm._m(6),_vm._m(7),_vm._m(8)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center cuatro"},[_vm._m(9),_vm._m(10),_vm._m(11)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center cinco"},[_vm._m(12),_vm._m(13),_vm._m(14)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center seis"},[_vm._m(15),_vm._m(16),_vm._m(17)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center siete"},[_vm._m(18),_vm._m(19),_vm._m(20)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center ocho"},[_vm._m(21),_vm._m(22),_vm._m(23)]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"caja flex-row-center siete"},[_vm._m(24),_vm._m(25),_vm._m(26)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2002")]),_c('div',{staticClass:"circle"},[_c('span')]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("NEDETEL")]),_c('span',{staticClass:"content wt"},[_vm._v(" obtiene la concesión para prestar Servicios Portadores de Telecomunicaciones. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail derecha"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("INICIO")]),_c('span',{staticClass:"content wt"},[_vm._v(" de operaciones comerciales en las 23 provincias del país ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2012")]),_c('div',{staticClass:"circle"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2014")]),_c('div',{staticClass:"circle"},[_c('span')]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("APERTURA")]),_c('span',{staticClass:"content wt"},[_vm._v(" de oficinas comerciales en la ciudad de Quito. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail derecha"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("NEDETEL")]),_c('span',{staticClass:"content wt"},[_vm._v(" se ubicó en el ranking de las 1000 empresas que más utilidades generan en el Ecuador ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2016")]),_c('div',{staticClass:"circle"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time4.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time5.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2017")]),_c('div',{staticClass:"circle"},[_c('span')]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("IMPLEMENTACIÓN")]),_c('span',{staticClass:"content wt"},[_vm._v(" de la primera red OTN de Nedetel, con 11 nodos ADD & DROP ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail derecha"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("CONSTRUCCIÓN")]),_c('span',{staticClass:"content wt"},[_vm._v(" de +5000 km de fibra interurbana y +2000 km de fibra urbana ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2018")]),_c('div',{staticClass:"circle"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time6.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time5.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2019")]),_c('div',{staticClass:"circle"},[_c('span')]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("RED OTN")]),_c('span',{staticClass:"content wt"},[_vm._v(" en 20 ciudades con capacidad de hasta 80 canales. Despliegue de la 1era etapa del Datacenter. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail derecha"},[_c('span')]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"title_section title wt"},[_vm._v("NEDETEL")]),_c('span',{staticClass:"content wt"},[_vm._v(" pone en marcha suinfraestructura hiperconvergentede CISCO ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2020")]),_c('div',{staticClass:"circle"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time7.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img flex-row-center align-row-center"},[_c('div',{staticClass:"cont-img"},[_c('img',{attrs:{"src":require("@/assets/img/nosotros/time4.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date flex-column-center align-column-center"},[_c('span',{staticClass:"title_section fecha"},[_vm._v("2020")]),_c('div',{staticClass:"circle"},[_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex-row-center align-row-center"},[_c('div',{staticClass:"cont-text flex-column-center align-column-center shadow"},[_c('div',{staticClass:"detail"},[_c('span')]),_c('span',{staticClass:"title_section title wt"},[_vm._v("ESCALAMOS")]),_c('span',{staticClass:"content wt"},[_vm._v(" 288 posiciones en el ranking de las 1000 empresas más importantes del Ecuador. ")])])])
}]

export { render, staticRenderFns }